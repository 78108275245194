import React from 'react';
import { Link } from 'react-router-dom';
import image from "../images/blog_detail_main.png";  

function CerezlerPolitikasi() {
  return (
    <>
    
      <div className="bred_crumb blog_detail_bredcrumb">
        <div className="container">
          <div className="bred_text">
            <h1>Çerezler Politikası</h1>
            <ul>
              <li><Link to="../">Anasayfa</Link></li>
              <li><span>»</span></li>
              <li><Link to=""> Çerezler Politikası</Link></li>
            </ul>
          </div>
        </div>
      </div>

      <section className="blog_detail_section">
        <div className="container">
          <div className="blog_inner_pannel">
            <div className="main_img">
              <img src={image} alt="image" />
            </div>
            <div className="info">
              <p>Waycer'in ("Şirket") olarak sahip olduğumuz https://www.waycer.com/ web sitemizin ("Web Sitesi") ziyaretçilerinin gizlilik ve kişisel verilerinin korunması haklarını gözeterek ziyaretçilerimize daha iyi bir kullanım deneyimi sağlayabilmek için kişisel verilerini işlemekte ve internet çerezleri kullanmaktayız. Bu Çerez Kullanımı Politikası ("Politika"), tüm Web Sitesi ziyaretçilerimize ve kullanıcılarımıza hangi tür çerezlerin hangi koşullarda kullanıldığını açıklamaktadır. Üyelik işlemlerinizin tamamlanarak Web Sitesi'ne üye olmanız halinde kişisel verilerinizin işlenmesine dair detaylı bilgilere Aydınlatma Metni'nde ulaşabilirsiniz.</p>
              <p>Bu Politika, Web Sitesi'ne hangi teknolojilerle, hangi yöntemlerle ve ne şekilde bağlandığınıza bağlı olmaksızın uygulanmaktadır. Dolayısıyla bu Politika'yı dikkatlice okuyup ileride inceleyebilmeniz açısından bir kopyasını almanızı tavsiye ederiz.</p>
            </div>

            <ul>
              <li>
                <p><span className="icon"><i className="icofont-check-circled"></i></span> <b>I. Reşit Olmayanlar</b><br />
                  18 yaşın altındaysanız Web Sitesi'ni ebeveynlerinizin refakati altında ziyaret etmeniz gerekmektedir. Ebeveynlerinizin refakati olmadan bu Web Sitesi'ni kullanmamanız ve hiçbir kişisel verinizi paylaşmamanız gerekmektedir.</p>
              </li>
              <li>
                <p><span className="icon"><i className="icofont-check-circled"></i></span> <b>II. Çerez Nedir ve Hangi Amaçlarla Kullanılır?</b><br />
                  Çerez, internet siteleri tarafından erişim sağladığınız bilgisayarınıza, cep telefonlarınıza, tabletlerinize veya diğer mobil cihazlarınıza kaydedilen küçük veri depolama dosyalarıdır. Bu dosyada Web Sitesi gezintinize ait bilgiler saklanır. Böylelikle erişim sağladığınız cihazlarınız Web Sitesi'ni tekrar kullandığınızda bu verilerinizi hatırlayacaktır. Dolayısıyla çerezler, Web Sitesi'ni etkili ve daha kolay kullanabilmeniz için gerekli ve önemlidir. Çerezler ayrıca Web Sitesi'nde ve üçüncü kişilerin web sitelerinde size daha uygun hizmet, ürün veya teklifler sunabilmemiz için kullanılmaktadır.</p>
              </li>
              <li>
                <p><span className="icon"><i className="icofont-check-circled"></i></span> <b>III. Çerezlerle Hangi Tür Verilerinizi İşliyoruz?</b><br />
                  Çerezler, türlerine bağlı olmak üzere, genel olarak, Web Sitesi'ne eriştiğiniz cihazda tarama ve kullanım tercihlerinize ilişkin verileri toplamaktadır. Bu veriler, eriştiğiniz sayfaları, incelediğiniz hizmet ve ürünlerimizi, Web Sitesi'nde yaptığınız gezintiye ilişkin tüm bilgileri kapsamaktadır.</p>
              </li>
              <li>
                <p><span className="icon"><i className="icofont-check-circled"></i></span> <b>IV. Hangi Tür Çerezleri Hangi Şekillerde Kullanıyoruz?</b><br />
                  Web Sitesi'nde farklı türlerde çerezler kullanmaktayız. Bunlar Web Sitesi'nin çalışmasını sağlamak için kullanılması zorunlu olan çerezler, işlev çerezleri, analiz/performans çerezleri ve hedefleme/reklam çerezleridir.</p>
              </li>
            </ul>

            <div>
              <h2>Kullanım Bakımından Çerez Türleri</h2>
              <p><b>Kullanılması Zorunlu Olan Çerezler:</b> Bu çerezler, Web Sitesi'nin düzgün şekilde çalışması için mutlaka gerekli olan çerezlerdir. Bu çerezlere, sistemin yönetilebilmesi, sahte işlemlerin önlenmesi için ihtiyaç vardır ve engellenmesi halinde Web Sitesi çalışamayacaktır.</p>
              <p><b>İşlev Çerezleri:</b> Bu çerezler size daha gelişmiş ve kolay bir kullanım deneyimi yaşatmak için kullanılan çerezlerdir. Örneğin önceki tercihlerinizi hatırlamak, Web Sitesi üzerinde yer alan bazı içeriklere rahatça erişmenizi sağlamak işlevlerini yerine getirmektedir.</p>
              <p><b>Analiz/Performans Çerezleri:</b> Bu çerezler, Web Sitesi'nin işleyişinizi analiz edip anlamımızı sağlayan ve sizinle etkileşime geçerek Web Sitesi'ni geliştirebilmemizi sağlamaktadır.</p>
              <p><b>Hedefleme/Reklam Çerezleri:</b> Bu çerezler, size ilginizi çekebilecek içerikleri saptayarak sunmamız için kullanılmaktadır.</p>
            </div>

            <div>
              <h2>Saklandığı Süre Bakımından Çerez Türleri</h2>
              <p><b>Kalıcı Çerezler (Persistent Cookies):</b> Kişinin bilgisayarında belirli bir tarihe veya kullanıcı tarafından silinene kadar varlığını sürdüren çerezlerdir.</p>
              <p><b>Oturum Çerezleri (Session Cookies):</b> Bu çerezler kullanıcının ziyaretini oturumlara ayırmak için kullanılır ve kullanıcıdan veri toplamaz. Çerez, kullanıcı ziyaret ettiği web sayfasını kapattığında veya belli bir süre pasif kaldığında silinir.</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CerezlerPolitikasi;
