import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import bannerShape1 from '../images/banner-shape1.png';
import bannerShape2 from '../images/banner-shape2.png';
import bannerShape3 from '../images/banner-shape3.png';
import bannerImage from '../images/ways-banner-image.png';
import driverLogo from '../images/driverlogo.png';
import agencyLogo from '../images/agencylogo.png';

function Home() {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  return (
    <>
      <section className="banner_section">
        <div className="container">
          <div className="row">
            <span className="banner_shape1">
              <img src={bannerShape1} alt="shape" />
            </span>
            <span className="banner_shape2">
              <img src={bannerShape2} alt="shape" />
            </span>
            <span className="banner_shape3">
              <img src={bannerShape3} alt="shape" />
            </span>
            <div className="col-lg-6 col-md-12" data-aos="fade-right">
              <div className="banner_text">
                <h1>
                  Güvenli İş Ortaklığı.
                  <br />
                  İşini Planla,
                  <br />
                  Kazancını Artır!
                </h1>
              </div>
            </div>
            <div className="col-lg-6 col-md-12" data-aos="fade-in">
              <div className="banner_image">
                <img className="moving_animation" src={bannerImage} alt="banner" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
            <defs>
              <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g className="parallax">
              <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
              <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
              <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
              <use xlinkHref="#gentle-wave" x="48" y="7" fill="#f6f4fe" />
            </g>
          </svg>
        </div>
      </section>

      <section className="row_am query_section">
        <div className="query_inner" data-aos="fade-in">
          <div className="container">
            <span className="banner_shape1">
              <img src={bannerShape1} alt="shape" />
            </span>
            <span className="banner_shape2">
              <img src={bannerShape2} alt="shape" />
            </span>
            <span className="banner_shape3">
              <img src={bannerShape3} alt="shape" />
            </span>
            <div className="section_title">
              <h2>Hakkımızda</h2>
              <p>
              VIP transfer sektöründe 14 yıllık tecrübemizi, WAYCER PARTNER ve WAYCER DRIVER uygulamalarını geliştirerek taçlandırdık. Hayalimiz her zaman sektöre en iyi hizmeti sunmak oldu. Sektörde karşılaştığımız sorunları ve işleyişini yakından bilmemiz, bu uygulamaların tasarımında belirleyici oldu. Uygulamalarımız, iş alan ve iş veren esnaflarımızın farklı platformlarda yaşadığı sorunları en aza indirmek, zamanı etkin kullanmak ve böylece karlılıklarını maksimize etmek amacıyla tasarlanmıştır. Ülkemizin İLK ve TEK mobil uygulaması olarak, siz değerli kullanıcılarımıza en iyi hizmeti sunmayı amaçlamaktayız.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="row_am how_it_works" id="how_it_work">
        <div className="container">
          <div className="how_it_inner">
            <div className="section_title" data-aos="fade-up" data-aos-delay="300">
              <h2>Demo Mobil Uygulamalarımız</h2>
            </div>
            <br />
            <div className="step_block">
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="step_box">
                    <div className="step_img" data-aos="fade-left">
                      <img src={driverLogo} alt="driver logo" />
                    </div>
                    <div className="step_text" data-aos="fade-right">
                      <h4>Waycer - Driver</h4>
                      <div className="app_icon">
                        <a href="https://play.google.com/store/apps/details?id=com.hulujans.waysappsurucu" target="_blank" rel="noopener noreferrer">
                          <i className="icofont-brand-android-robot"></i>
                        </a>
                        <a href="https://apps.apple.com/us/app/WAYCER-driver/id6477337081" target="_blank" rel="noopener noreferrer">
                          <i className="icofont-brand-apple"></i>
                        </a>
                      </div>
                 
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="step_box">
                    <div className="step_img" data-aos="fade-left">
                      <img src={agencyLogo} alt="partner logo" />
                    </div>
                    <div className="step_text" data-aos="fade-right">
                      <h4>Waycer - Partner</h4>
                      <div className="app_icon">
                        <a href="https://play.google.com/store/apps/details?id=com.hulujans.waysapp" target="_blank" rel="noopener noreferrer">
                          <i className="icofont-brand-android-robot"></i>
                        </a>
                        <a href="https://apps.apple.com/us/app/WAYCER-partner/id6477295353" target="_blank" rel="noopener noreferrer">
                          <i className="icofont-brand-apple"></i>
                        </a>
                      </div>
                  
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="row_am faq_section">
        <div className="container">
          <div className="section_title" data-aos="fade-up" data-aos-delay="300">
            <h2>
              Sıkça <span>Sorulan</span> Sorular
            </h2>
          </div>
          <div className="faq_panel">
            <div className="accordion" id="accordionExample">
              {[
                {
                  id: 1,
                  question: "WAYCER PARTNER ve WAYCER DRIVER NEDEN KULLANMALIYIM ?",
                  answer:
                    "Farklı platformlarda iş veren ve iş alanlar arasında yaşanan karışıklıkları ortadan kaldırarak hepimiz için önemli olduğunu bildiğimiz zamanı en efektif şekilde kullanmanızı sağlar ve minimum maliyet oluşturup karlılığınızı maksimum seviyeye çıkarır."
                },
                {
                  id: 2,
                  question: "TÜRKİYE'NİN HER YERİNDE KULLANABİLİR MİYİM ?",
                  answer:
                    "İlk etapta belirlemiş olduğumuz pilot bölgelerde güvenli şekilde iş alabilir ve iş verebilirsiniz. Hedefimiz en kısa süre içerisinde Türkiye'nin her şehrinden optimum seviyede iş alıp, iş verme olanağanı sağlamak olacaktır."
                },
                {
                  id: 3,
                  question: "UYGULAMAYI İNDİRMEK ÜCRETLİ Mİ ?",
                  answer:
                    "Waycer Partner ve Waycer Driver Uygulamamızı AppStore ve PlayStore'dan ücretsiz olarak indirebilirsiniz. Sistemimiz abonelik esasına dayalı çalışacağından Ücretsiz kullanım süresi sonrası minimum aylık üyelik ücreti alınacaktır."
                },
                {
                  id: 4,
                  question: "PARA ALIŞ VERİŞİ NASIL OLACAK ?",
                  answer:
                    "Onaylanan ve tamalanan iş ilanlarının ödemeleri uygulama üzerinden yapılamaz. İş verenin ödeme şeklinde belirttiği taahhüt dahilinde gerçekleşir."
                },
                {
                  id: 5,
                  question: "İŞ ALIP İŞ VERME SINIRI VAR MI ?",
                  answer:
                    "Waycer Partner ve Waycer Driver uygulamasını aktif hale getirip planlamanızı en doğru şekilde yaptıktan sonra iş alma ve iş verme sınırı yoktur."
                },
                {
                  id: 6,
                  question: "ÇALIŞMA SAATLERİMİZ ESNEK OLACAK MI ?",
                  answer:
                    "Çalışma saatlerinizin esnekliği Waycer Partner ve Waycer Driver uygulamamızı Aktif / Pasif durumuna getirerek dilediğiniz zaman diliminde, ihtiyaçlarınız doğrultusunda ve planlamanız dahilinde kullanabiliirsiniz."
                },
                {
                  id: 7,
                  question: "ÜYELİK ŞARTLARI NELERDİR ?",
                  answer:
                    "Waycer Partner ve Waycer Driver uygulamamız için iş veren ve iş alandan istenen gerekli belgeler uygulama aracılığı ile tarafımıza iletilir. Bu belgeler incelendikten sonra yapmış olduğunuz başvuru en kısa sürede sonuçlanır. Burada amaçlanan legal olmayan işlerin önüne geçmektir."
                },
                {
                  id: 8,
                  question: "UYGULAMANIZDA DÜŞÜK FİYAT OLACAK MI ?",
                  answer:
                    "Uygulamanın amacı değişik platformlarda yaşadığınız karışıklıkları önlemek, kaliteli hizmeti arttırarak güncel rakamları en üst seviyede tutmaktır."
                },
                {
                  id: 9,
                  question: "İŞ ALAN ve İŞ VEREN ANLAŞMAZLIKLARINDA MUHATTABIM KİM OLACAK ?",
                  answer:
                    "Waycer Partner ve Waycer Driver uygulamamızın kullanımı için gerekli belgeler tarafımıza iletilip onaylandıktan sonra oluşabilecek her türlü anlaşmazlıklarda Waycer hukuki yaptırımlarla yanınızda olacaktır. Cezai duruma düşen kullanıcıların sisteme alınmamak üzere üyelikleri sonlandırılacaktır."
                },
                {
                  id: 10,
                  question: "BİLGİLERİMİN GÜVENLİĞİ NASIL SAĞLANACAK",
                  answer:
                    "Bilgilerinizi Google Sunucularında tutuyoruz. Verilerinizin Güvenliğini Google Sunucular tarafından sağlanmaktadır."
                }
              ].map(({ id, question, answer }) => (
                <div className="card" data-aos="fade-up" key={id}>
                  <div className="card-header" id={`heading${id}`}>
                    <h2 className="mb-0">
                      <button
                        type="button"
                        className="btn btn-link"
                        data-toggle="collapse"
                        data-target={`#collapse${id}`}
                        aria-expanded="true"
                        aria-controls={`collapse${id}`}
                      >
                        <i className="icon_faq icofont-plus"></i>
                        {question}
                      </button>
                    </h2>
                  </div>
                  <div
                    id={`collapse${id}`}
                    className="collapse"
                    aria-labelledby={`heading${id}`}
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">{answer}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
