import React, { useEffect } from 'react';
import logo from '../images/logo.svg';  // Adjust path as necessary
 

function Header() {
 

  return (
    <>
      <div className="page_wrapper">

      <div id="preloader">
        <div id="loader"></div>
      </div>

      <header>
        <div className="container">
          <nav className="navbar navbar-expand-lg">
            <a className="navbar-brand" href="../">
              <img src={logo} height="150" alt="logo" />
            </a>
          </nav>
        </div>
      </header>
      </div>
    </>
  );
}

export default Header;
